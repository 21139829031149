export const projects = [
  {
    slug: 'planter-des-haies-sur-mon-exploitation',
    text: 'Geoffrey et son frère se lancent dans un projet pour replanter des haies sur leur exploitation. Leur objectif ? Créer des refuges pour la faune, verdir les paysages, protéger les sols et réduire leur empreinte carbone. En s’engageant dans cette démarche durable, ils veulent non seulement lutter contre le changement climatique, mais aussi laisser un héritage positif pour les générations futures. Soutenez les deux frères pour qu’ils fassent de leur exploitation un modèle plus respectueux de la nature !',
    image: 'ricore-josue-geoffrey.jpg',
    order: 0,
    title: 'Planter des haies sur mon exploitation',
    fullname: 'Geoffrey Magisson',
    place: 'Montzéville, Meuse',
    anchor: 'geoffrey',
  },
  {
    slug: 'ameliorer-mon-autonomie-energetique',
    text: 'Découvrez deux agriculteurs, père et fils, qui produisent du lait de vache pour Ricoré et des céréales. Avec 115 vaches traites par des robots, la traite et le refroidissement du lait entraînent un coût énergétique important tous les mois. Leur projet innovant d’installation d’un tracker solaire de 11 mètres, qui suit le soleil, leur permettra de produire 26% de l’électricité nécessaire à leur exploitation et de réduire leur empreinte carbone. Soutenez ce projet et aidez-les à franchir un cap essentiel vers une agriculture plus durable !',
    image: 'ricore-camille.jpg',
    order: 1,
    title: 'Améliorer mon autonomie énergétique',
    fullname: 'Camille Peureux',
    place: 'Boureuilles, Meuse',
    anchor: 'camille',
  },
  {
    slug: 'elevage-de-vaches-laitieres-bio',
    text: 'Régis et son associé gèrent une ferme de 100 vaches laitières 100% alimentées à l’herbe et au pâturage. Ils souhaitent réaliser un bilan sur les haies présentes actuellement sur leur exploitation pour savoir s’il est nécessaire d’en planter de nouvelles, ainsi que des arbres afin de mieux faire face aux changements climatiques sur ces prairies. Cela permettra d’améliorer le bien-être animal de leur troupeau en leur apportant de la fraîcheur Votez pour ce projet et aidez Régis et son associé à réaliser un bilan agroforestier sur leur exploitation !',
    image: 'ricore-regis.jpg',
    order: 2,
    title: 'Élevage de vaches laitières bio',
    fullname: 'Régis Tricoteaux',
    place: 'Combeferre, Larzac',
    anchor: 'regis',
  },
]
