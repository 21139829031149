import { Text } from '@chakra-ui/react'
import { MiimosaCestQuoi, FinancementParticipatif } from '@components/common'

export const questions = [
  {
    title: 'Comment fonctionne la mécanique des opérations ?',
    bgColor: 'white',
    textColor: 'black',
    text: (
      <>
        <Text>
          Avec 18 usines en France qui transforment près d’1 million de tonnes de matières premières agricoles chaque
          année, le groupe Nestlé a construit au fil du temps une relation forte et pérenne avec le monde agricole
          français et a une responsabilité au sein de cet écosystème. Alors que l’urgence climatique s’intensifie,
          menaçant la viabilité de notre modèle alimentaire actuel, nous avons
          <span style={{ fontWeight: '700' }}>
            {' '}
            choisi de faire du soutien à la transition agroécologique une priorité{' '}
          </span>
          de notre plan d’action pour les prochaines années. Dans cette course contre la montre climatique, notre
          objectif est d’accompagner des agriculteurs français à transitionner vers des systèmes alimentaires
          régénérateurs, comme l’agriculture régénératrice ou l’agriculture biologique, afin de leur permettre de
          produire en quantité tout en prenant soin de leurs sols et de la biodiversité.
        </Text>
      </>
    ),
  },
  {
    title: 'Génération Régénération, c’est quoi ?',
    bgColor: 'white',
    textColor: 'black',
    text: (
      <>
        <Text>
          En 2023, nous soutenons les projets de 4 agriculteurs situés autour des usines de céréales de petit-déjeuner
          de Rumilly (Haute-Savoie) et d’Itancourt (Aisne). Ils sont producteurs de blé en agriculture biologique et
          souhaitent poursuivre leurs efforts pour des pratiques agricoles plus durables.
          <br />
          Guy et Sébastien, comme Méryl et Benoît, conduisent leurs fermes en agriculture biologique et adoptent des
          principes agroécologiques pour travailler avec la nature et améliorer la qualité de leurs sols.
        </Text>
      </>
    ),
  },
  {
    ...MiimosaCestQuoi,
    bgColor: 'white',
    textColor: 'black',
  },
  {
    ...FinancementParticipatif,
    bgColor: 'white',
    textColor: 'black',
  },
  {
    title: 'RICORE® en quelques mots',
    bgColor: 'white',
    textColor: 'black',
    text: (
      <>
        <Text fontWeight="bold">
          RICORÉ®, c’est l’alliance parfaite entre la douceur de la chicorée et la force du café.
        </Text>
        <Text>
          La chicorée adoucit naturellement l’amertume du café tout en préservant ses arômes, et apporte une fois
          torréfiée des notes de noisette et de caramel aux délicieuses boissons RICORÉ®. Un goût unique et subtil que
          vous allez adorer !
        </Text>
        <Text fontWeight="bold">RICORÉ® Original, l’allié bien-être</Text>
        <Text>
          La chicorée soluble présente dans la boisson RICORÉ® Original est riche en fibres, qui participent au bon
          fonctionnement du transit. Lorsqu’elle est reconsituée avec du lait, la boisson RICORÉ® Original est également
          source de magnesium. Elle apporte 15% des apports quotidiens de référence en magnésium.
        </Text>
        <Text fontWeight="bold">RICORÉ® : Un savoir-faire Français</Text>
        <Text>
          Depuis 1953 la majorité des produits de la marque RICORÉ® sont fabriqués en France sur les sites de Dieppe,
          Challerange et Pontarlier. La sélection des matières premières, la torréfaction du café et de la chicorée (qui
          développe les arômes), et enfin l’extraction puis le séchage de la racine de chicorée, sont les étapes
          essentielles de la fabrication des produits RICORÉ®. La chicorée est une plante cultivée majoritairement dans
          le nord de la France. Plusieurs étapes sont nécessaires pour la transformer en chicorée soluble: la sélection,
          la torréfaction de la racine, la percolation, le séchage et enfin le conditionnement.
        </Text>
      </>
    ),
  },
]
